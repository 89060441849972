/* eslint-disable react-hooks/exhaustive-deps */
import { Group, Thing, ThingType } from '@eagle/core-data-types';
import {
  AppliedFilter,
  AppliedFilterType,
  CacheDataTypes,
  entityGroup,
  ErrorPage,
  evaluate,
  FeatureIcons,
  FilterDataTypes,
  filterDeletedCache,
  FilterFieldNewProps,
  FilterPathIdentifiers,
  filterToQuery,
  FilterTypes,
  FILTER_OUT,
  FindItemsDeferredResult,
  getListResultDescription,
  ListPage,
  ListPageQuery,
  MiddleSpinner,
  NewFilter,
  NEW_FILTER_FLAG,
  PortalFeatureIcons,
  ReplacePath,
  SAVED_FILTER_KEY,
  SearchResults,
  THING_FILTER_STORAGE_KEY,
  trackEvent,
  TRACK_THING_LIST_ROW_DEFAULT_TEMPLATE,
  T_MANY,
  T_ONE,
  useAuthenticated,
  useCustomRoutes,
  useDynamicModule,
  useFetchAllCache,
  useFlags,
  usePromise,
  useUiTemplate
} from '@eagle/react-common';
import Axios from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const thingListItemRenderer = (data: Thing, template: unknown): JSX.Element => {
  return <>{evaluate(template, data)}</>;
};

const FILTER_REPLACE_PATHS: ReplacePath[] = [
  {
    old: FilterTypes.THING,
    new: FilterTypes.ID,
  },
];

export const ThingList: FC = () => {
  const { t } = useTranslation(['common', 'track', 'terms']);
  const { thing: customRoutesThing } = useCustomRoutes();
  const navigate = useNavigate();
  const { account, restClient } = useAuthenticated();
  const flags = useFlags();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const { template, loaded: templateLoaded } = useUiTemplate('track-thing-list-row', TRACK_THING_LIST_ROW_DEFAULT_TEMPLATE);

  const groupCache = useFetchAllCache(CacheDataTypes.GROUP);
  const [groups, groupError, groupState] = usePromise(
    filterDeletedCache<Group>(groupCache),
    [account, groupCache],
  );

  const thingTypesCache = useFetchAllCache(CacheDataTypes.THING_TYPE);
  const [thingTypes, thingTypesError, thingTypesState] = usePromise(
    filterDeletedCache<ThingType>(thingTypesCache),
    [thingTypesCache],
  );

  const findThings = ({ filters, pagination, search }: ListPageQuery): FindItemsDeferredResult<Thing> => {
    const cancelToken = Axios.CancelToken.source();

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.thing.getAllV1Paged({
        ...pagination,
        ...(search ? { search } : {}),
        filter: { ...filterToQuery(filters, FILTER_REPLACE_PATHS), ...FILTER_OUT.deleted },
        sort: !search ? JSON.stringify({ display: 'asc' }) : undefined,
      }, { cancelToken: cancelToken.token }).then((response) => {
        const matchCount = response.count;
        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'terms:thing', filters, search, t });

        if (search) {
          trackEvent('keyword_search', 'returned_results', 'thing_list', { 'results_type': 'thing', 'results_count': matchCount, 'search_term': search });
        }

        return {
          result: {
            results: response.entities,
            itemCount: matchCount,
          },
          resultDescription,
        };
      }),
    };
  };

  const navigateToThing = (thing: Thing): void => {
    navigate(`/${customRoutesThing}/${encodeURIComponent(thing._id)}`);
  };

  const buildHref = (item: Thing): string => {
    return `/${customRoutesThing}/${encodeURIComponent(item._id)}`;
  };

  const renderFilterContent = (
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ): JSX.Element => {
    const filterFields: FilterFieldNewProps[] = [
      {
        apiUrl: '/api/v1/thing',
        attributes: {
          typeCache: thingTypesCache,
          typePath: FilterTypes.THING_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.select-a-thing'),
        pathIdentifier: FilterPathIdentifiers.THING,
        propertyLabel: t('terms:thing', { count: T_MANY }),
        typePropertyName: FilterTypes.THING,
      },
      {
        dataType: FilterDataTypes.CACHE,
        entityCache: groupCache,
        fieldLabel: t('common:component.filter.labels.select-a-group'),
        pathIdentifier: FilterPathIdentifiers.GROUP,
        propertyLabel: t('common:terms.group', { count: T_MANY }),
        typePropertyName: FilterTypes.GROUP,
      },
    ];

    return (
      <NewFilter
        filterFields={filterFields}
        filters={filters}
        onCloseClicked={() => setFilterOpen(false)}
        onFiltersChanged={onFiltersChanged}
        savedFilterKey={SAVED_FILTER_KEY}
        storageKey={THING_FILTER_STORAGE_KEY}
        data-testid="things-new-filter"
      />
    );
  };

  if (thingTypesState === 'pending' || groupState === 'pending' || !moduleLoaded || !templateLoaded) return <MiddleSpinner />;
  if (groupError) return <ErrorPage error={groupError} />;
  if (thingTypesError) return <ErrorPage error={thingTypesError} />;

  return (
    <ListPage<Thing>
      data-testid='thing-list'
      id='thing'
      filterFields={[
        {
          entityTypes: thingTypes ?? [],
          propertyLabel: t('common:common.labels.type'),
          typePropertyName: FilterTypes.THING_TYPE,
        },
        {
          entityTypes: groups ? entityGroup(groups) : [],
          propertyLabel: t('common:terms.group', { count: T_ONE }),
          typePropertyName: FilterTypes.GROUP,
        },
      ]}
      icon={module?.ThingIcon && <module.ThingIcon />}
      navigateToEntity={navigateToThing}
      onQueryChanged={findThings}
      pathIdentifiers={[FilterPathIdentifiers.THING, FilterPathIdentifiers.GROUP]}
      renderContent={(highlightIndex, isLoading, items, text) => (
        <SearchResults<Thing>
          data-testid='thing-search-results'
          id='thing'
          buildHref={buildHref}
          handleFormatListItem={(item) => thingListItemRenderer(item, template)}
          highlightIndex={highlightIndex}
          initialInstructions={t('common:component.lookup.hint.initial')}
          isLoading={isLoading}
          noResultsInstructions={t('common:page.thing-list.hint.none')}
          searchResults={items}
          selectedItem={null}
          text={text}
        />
      )}
      renderFilterContent={flags[NEW_FILTER_FLAG] ? renderFilterContent : undefined}
      storageKey={THING_FILTER_STORAGE_KEY}
      title={t('terms:thing', { count: T_MANY })}
    />
  );
};
